.Home {
    //sposition: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
  
    &__TextWrapper {
      display: flex;
      justify-content: center;
  
      h1 {
        color: var(--text-secondary);
        margin: 0;
        font-weight: 500;
        font-size: 8rem;
        line-height: 10rem;
        position: relative;
        overflow: hidden;
        white-space: nowrap;
    }
    
    .typing {
        display: inline-block;
        width: 14ch;
        position: relative;
        white-space: nowrap;
      }

    }

    &__Buttons
    {
        display: flex;
        justify-content: center;

        button
        {
            cursor: pointer;
            padding: 10px 70px;
            font-size: 2rem;
            background: var(--button-active);
            color: var(--text-primary);
            border: 1.5px solid var(--mainColor);
            border-radius: 4px;
            font-weight: 500;
            margin: 15px 15px;

            &:hover
            {
                background: var(--button-hover);
                color: var(--primary-purple);
            }
        }
    }
  
  }
  
  @media only screen and (max-width: 550px) {
    .Home__TextWrapper h1 {
      font-size: 3rem;
      line-height: 5rem;
    }
  }
  
  @media only screen and (min-width: 551px) and (max-width: 768px) {
    .Home__TextWrapper h1 {
      font-size: 4rem;
      line-height: 6rem;
    }
  }
  
  @media only screen and (min-width: 769px) and (max-width: 990px) {
    .Home__TextWrapper h1 {
      font-size: 5rem;
      line-height: 7rem;
    }
  }