.Wrapper
{
    display: flex;
    align-items: center;
    padding: 20px;
    border-bottom: 1px dashed var(--primary-purple);

    h2 
    {
        font-size: 32px;
        font-weight: 700;
        letter-spacing: 1.5px;
        line-height: 1rem;
        text-transform: capitalize;
        color: var(--secondary-purple);
        display: flex;
        flex-grow: 1;
    }

    span 
    {
        svg
        {
            color: var(--secondary-purple);
        }
    }
}