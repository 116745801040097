:root {
    --primary-purple: #4B0082;
    --secondary-purple: #8A2BE2;
    --background-dark: #2E003E;
    --text-primary: #FFFFFF;
    --text-secondary: #D1C4E9;
    --button-active: #8E44AD;
    --button-hover: #D1AFFF;
  }

*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

html
{
    font-size: 62.5%;
    height: 100%;
}

body
{
    background: var(--background-dark);
    height: 100%;
}

#root
{
    height: 100%;
}
