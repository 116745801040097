.App
{
    height: 100%;

    &__MainpageContent
    {
        height: calc(100% - 80px);
    }

    .particlesContainer
    {
        position: absolute;
        z-index: 100;
        pointer-events: none;
    }

    #tsparticles
    {
        opacity: 0;
        animation: fadeIn 2s forwards;
    }
}

@keyframes fadeIn {
    to {
      opacity: 1;
    }
  }