.About__Content
{
    gap: 20px;
    padding: 20px;
    width: 100%;

    p
    {
        color: var(--text-primary);
        font-size: 1.8rem;
        line-height: 1.65;
        padding-bottom: 20px;
        border-bottom: 1px dashed var(--secondary-purple);
    }

    .personalInformation
    {
        margin-bottom: 10px;
        font-size: 2.5rem;
        font-weight: 700;
        color: var(--secondary-purple);
        padding-top: 20px;
    }

    ul > li
    {
       line-height: 1rem;
       margin-bottom: 15px;

       .Title,  .Value
       {
            font-size: 18px;
            font-weight: 700;
            letter-spacing: 0.5px;
            position: relative;
            display: inline-block;
            margin-right: 16px;
            padding-bottom: 5px;
            color: var(--text-primary);
       }
    }
}