.contact-section {
    padding: 50px 20px;
    background: var(--background-dark);
    text-align: center;

    .contact-content {
        h2 {
            font-size: 2rem;
            color: var(--text-primary);
            margin-bottom: 20px;
        }

        p {
            font-size: 1rem;
            color: var(--text-primary);
            margin-bottom: 40px;
        }

        .contact-form {
            max-width: 600px;
            margin: 0 auto;
            background-color: var(--background-dark);
            padding: 20px;
            border-radius: 8px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

            .form-group {
                margin-bottom: 15px;
                text-align: left;

                label {
                    font-size: 1rem;
                    color: var(--text-primary);
                    display: flex;
                    align-items: center;
                }

                input,
                textarea {
                    color: var(--text-primary);
                    width: 100%;
                    padding: 10px;
                    font-size: 1rem;
                    border: 1px solid var(--secondary-purple);
                    background: var(--primary-purple);
                    border-radius: 4px;
                    margin-top: 8px;
                    transition: border-color 0.3s ease;

                    &:focus {
                        outline: none;
                        border-color: --text-primary;
                    }
                }
            }

            .submit-btn {
                background: var(--button-active);
                color: var(--text-primary);
                font-size: 1.1rem;
                padding: 12px 20px;
                border: none;
                border-radius: 4px;
                cursor: pointer;
                transition: background-color 0.3s ease;

                &:hover {
                    background: var(--button-hover);
                }
            }
        }

        .submission-message {
            margin-top: 20px;
            color: var(--text-primary);
            font-size: 1rem;
        }
    }
}

@media (max-width: 768px) {
    .contact-content {
        padding: 20px;

        .contact-form {
            width: 100%;
        }
    }
}