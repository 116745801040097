.Navbar {
  background: var(--background-dark);
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 999;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &__Container {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    padding: 0 20px;
    justify-content: space-between;

    &__Logo {
      display: flex;
      align-items: center;
      cursor: pointer;

      &__Img {
        max-height: 60px;
        object-fit: contain;
        transition: transform 0.3s ease, filter 0.3s ease;
        filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.5));
      }

      &:hover &__Img {
        transform: scale(1.1);
        filter: drop-shadow(3px 3px 6px rgba(0, 0, 0, 0.7));
      }
    }

    &__Menu {
      display: flex;
      list-style: none;
      text-align: center;
      gap: 1.5rem;

      &__Item {
        position: relative;
        font-size: 1.6rem;
        line-height: 48px;

        &__Link {
          color: var(--primary-purple);
          text-decoration: none;
          padding: 0.5rem;
          transition: color 0.3s ease, transform 0.3s ease;

          &:hover {
            color: var(--secondary-purple);
            transform: translateY(-2px);
          }

          &:focus {
            outline: 2px solid var(--secondary-purple);
            outline-offset: 2px;
          }
        }

        &::after {
          content: '';
          display: block;
          height: 3px;
          width: 0;
          background: transparent;
          transition: all 0.3s ease;
        }

        &:hover::after {
          width: 100%;
          background: var(--secondary-purple);
        }
      }
    }
  }

  &__Icon {
    display: none;
    cursor: pointer;
    color: var(--secondary-purple);
    font-size: 1.8rem;

    @media screen and (max-width: 768px) {
      display: block;
    }
  }
}

/* Mobile Menu Styles */
@media screen and (max-width: 768px) {
  .Navbar__Container__Menu {
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 80px;
    right: 0;
    width: 100%;
    background: var(--background-dark);
    box-shadow: -4px 0 6px rgba(0, 0, 0, 0.1);
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease-in-out;
    border-radius: 0 0 8px 8px;
    padding: 20px;

    &.Active {
      opacity: 1;
      visibility: visible;
    }

    &__Item {
      margin-bottom: 1rem;

      &__Link {
        font-size: 1.4rem;
      }
    }
  }
}
